<template>
  <nav>
    <v-app-bar app color="#102126" dark flat>
      <v-app-bar-nav-icon
        @click="drawer = true"
        class="d-flex d-sm-none"
      ></v-app-bar-nav-icon>
      <div class="d-flex align-center justify-center ma-auto">
        <v-img
          alt="Zaawink Logo"
          class="shrink mr-2"
          contain
          src="@/assets/Zaawink-logo.png"
          transition="scale-transition"
          :width="imgWidth"
        />
        <v-img src="@/assets/Zaawink-blue-text.png" :max-width="txtWidth" />
        <!-- <v-toolbar-title> ZaaWink </v-toolbar-title> -->
      </div>

      <v-spacer class="d-none d-sm-flex"></v-spacer>

      <div class="d-none d-sm-flex">
        <v-btn text to="/" class="mx-1 mx-md-2">
          <span class="text-lg-h6 text-md-subtitle-1">HOME</span>
        </v-btn>
        <v-btn text to="/about" class="mx-1 mx-md-2">
          <span class="text-lg-h6 text-md-subtitle-1">ABOUT</span>
        </v-btn>
        <v-btn text router-link to="/team" class="mx-1 mx-md-2">
          <span class="text-lg-h6 text-md-subtitle-1">TEAM</span>
        </v-btn>
        <v-btn text class="mx-1 mx-md-2">
          <span class="text-lg-h6 text-md-subtitle-1">LOGIN</span>
        </v-btn>
      </div>
      <!-- <v-btn
      href="https://github.com/vuetifyjs/vuetify/releases/latest"
      target="_blank"
      text
    >
      <span class="mr-2">Latest Release</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn> -->
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item router-link to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item router-link to="/about">
            <v-list-item-icon>
              <v-icon>mdi-information</v-icon>
            </v-list-item-icon>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>

          <v-list-item router-link to="/team">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Team</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-login</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
  }),
  computed: {
    imgWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 30;
        case "sm":
          return 30;
        case "md":
          return 30;
        case "lg":
          return 40;
        case "xl":
          return 40;
        default:
          return 40;
      }
    },
    txtWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 150;
        case "sm":
          return 140;
        case "md":
          return 150;
        case "lg":
          return 200;
        case "xl":
          return 200;
        default:
          return 200;
      }
    },
  },
};
</script>