import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import terms from '../components/TermsConditions.vue'
import privacy from '../components/PrivacyPolicy.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/About.vue')
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import(/* webpackChunkname: "team" */ '../components/Team.vue')
  },
  {
    path: '/TermsConditions',
    name: 'Terms',
    component: terms
  },
  {
    path: '/PrivacyPolicy',
    name: 'Privacy',
    component: privacy
  }
]

const router = new VueRouter({
  mode: "hash",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
})

export default router
