<template>
  <v-dialog v-model="show" max-width="450px" class="subscribe-dialog">
    <v-card dark class="pa-5 py-12 dialog" style="overflow: hidden">
      <v-btn
        small
        dark
        icon
        class="mb-3 pa-0"
        text
        style="float: right; margin-top: -39px; margin-right: -12px"
        @click.stop="show = false"
        >x</v-btn
      >
      <div id="mc_embed_signup" style="background: transparent !important;">
        <form
          action="https://zaawink.us5.list-manage.com/subscribe/post?u=e5b34bb23d23e3ed037ed5190&amp;id=967ba3a666"
          method="post"
          id="mc-embedded-subscribe-form"
          style="padding: 0px;"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <div id="mc_embed_signup_scroll" class="text-center">
            <h2 class="dialog-text mx-auto mb-6">Let's stay in touch!</h2>
            <h4 class="mb-6 dialog-text mx-auto">
              Subscribe to our newsletter to stay up to date on our product
              launch and investment opportunities.
            </h4>
            <div class="mc-field-group pb-0" style="width: 100%; min-height: 50px;">
              <input
                type="email"
                value=""
                name="EMAIL"
                class="required email email-input mx-auto"
                placeholder="Email Address"
                id="mce-EMAIL"
              />
            </div>
            <div id="mce-responses" class="clear">
              <div
                class="response"
                id="mce-error-response"
                style="display: none"
              ></div>
              <div
                class="response"
                id="mce-success-response"
                style="display: none"
              ></div>
            </div>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px" aria-hidden="true">
              <input
                type="text"
                name="b_e5b34bb23d23e3ed037ed5190_967ba3a666"
                tabindex="-1"
                value=""
              />
            </div>
            <div class="clear">
              <input
                type="submit"
                value="SUBSCRIBE"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button submit-btn mx-auto"
              />
            </div>
            <v-btn
              color="white"
              text
              small
              class="close-btn"
              @click.stop="show = false"
              >No Thanks</v-btn
            >
          </div>
        </form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style scoped>
.dialog {
  background: #179598;
  background: -webkit-linear-gradient(top left, #179598, #056B8B);
  background: -moz-linear-gradient(top left, #179598, #056B8B);
  background: linear-gradient(to bottom right, #179598, #056B8B);
}
.dialog-text {
  color: white;
  width: 350px;
}
.email-input {
  width: 350px !important;
  color: #fafafa;
}
.email-input:focus-visible {
  border: 2px solid #179b9e !important;
  outline: none;
}
.submit-btn {
  color: #179b9e !important;
  font-weight: 500 !important;
  width: 350px !important;
  background-color: #fafafa !important;
  /* border: 2px solid #179b9e !important; */
}
.submit-btn:hover {
  background-color: #158a8d;
  /* border: 2px solid #158a8d !important; */
  outline: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}
.submit-btn:before {
  vertical-align: top;
  padding: 8px 20px 6px;
}
.close-btn:before {
  background-color: transparent;
}
.dialog-border {
  border-width: 8px;
	border-style: solid;
	border-top: 0;
	border-right: 0;
  border-color: #179b9e !important;
  border-radius: 0px !important;
}
.subscribe-dialog {
  border-radius: 0px !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
}
</style>