<template>       
     <div name="termly-embed" data-id="0d8b6557-9413-44b3-9cc4-51a8066ba2d3" data-type="iframe"></div>
</template>

<script>

export default {
  name: "terms",

  components: {
    
  },
  mounted() {
  (function(d, s, id) {
  var js, tjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) return;
  js = d.createElement(s); js.id = id;
  js.src = "https://app.termly.io/embed-policy.min.js";
  tjs.parentNode.insertBefore(js, tjs);
}(document, 'script', 'termly-jssdk'));
  },
  data: () => ({
    //
  })
};
</script>