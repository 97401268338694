<template>       
     <div name="termly-embed" data-id="17954961-c516-4f9d-9bc5-caa0e163f6ff" data-type="iframe"></div>
</template>

<script>

export default {
  name: "privacy",

  components: {
    
  },
  mounted() {
    (function(d, s, id) {
      var js, tjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(js, tjs);
    }(document, 'script', 'termly-jssdk'));
  },

  data: () => ({
    //
  })
};
</script>
