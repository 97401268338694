<template>
  <div>
    <script
      type="application/javascript"
      src="https://app.termly.io/embed.min.js"
      data-auto-block="on"
      data-website-uuid="e80b6cf4-7b33-4cdd-bac1-f2c8a0d9ed6c"
    ></script>
    <v-app>
      <NavBar />
      <v-main>
        <Modal v-model="showModal" @close="showModal = false" />
        <router-view />
      </v-main>
      <Footer />
    </v-app>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: "App",

  components: {
    NavBar,
    Footer,
    Modal,
  },

  data: () => ({
    showModal: false,
  }),
  methods: {
    activate() {
      setTimeout(() => (this.showModal = true), 2000);
    },
  },
  // Used for title naming
  mounted() {
    document.title = "ZaaWink";
    this.activate();
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,700|Montserrat:400,300,700");

#app {
  font-family: "DM Sans", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
body {
  overflow-x: hidden;
}
</style>
