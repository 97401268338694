<template>
  <div>
    <v-card class="background" tile elevation="0">
      <v-row align="center" justify="center" class="px-md-16 mx-lg-13">
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="6"
          align="center"
          class="pa-md-5 mt-6 mt-sm-10 mt-md-15 pl-md-10"
        >
          <div height="461px">
            <v-img :max-width="imgWidth" src="@/assets/ZazaOne.png" />
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="6"
          class="
            mx-auto
            px-12
            mx-md-0
            px-sm-5
            pa-md-5
            mt-sm-0
            pl-lg-10
            text-left
          "
          style="width: 50%"
          align="center"
        >
          <p
            class="
              mainText
              text-h4 text-lg-h2 text-md-h3 text-sm-h4
              font-weight-medium
              mt-12
              mb-4 mb-sm-2
              mx-auto
            "
          >
            Creating a Culture of <br class="hidden-custom" />Health Awareness™
          </p>
          <p
            class="
              mainSubText
              text-lg-h6
              pr-lg-16
              mr-10 mr-sm-0
              px-lg-7 px-sm-0
            "
          >
            ZaaWink provides personnel automated
            <br class="hidden-small" />temperature monitoring for businesses.
          </p>
          <v-btn
            large
            rounded
            href="mailto:info@zaawink.com"
            target="_blank"
            class="btn-center mt-2 font-weight-bold"
            >REQUEST A QUOTE NOW</v-btn
          >
        </v-col>
      </v-row>
    </v-card>

    <v-row align="center" justify="center">
      <v-col class="my-16 mx-4 mx-sm-0">
        <v-container>
          <h1
            class="
              infoText
              text-h4 text-lg-h2 text-md-h3 text-sm-h4
              font-weight-medium
              mb-6
            "
          >
            Who We Are
          </h1>

          <p
            class="
              infoSubText
              text-lg-h6 text-left text-sm-center
              font-weight-regular
            "
          >
            ZaaWink uses temperature-capable smartwatches integrated with
            our Software as a Service to provide consistent, accurate, and
            automated temperature monitoring. Businesses can subscribe to our
            services that offer temperature monitoring, interactive business
            maps, and a business administration dashboard. These features
            demonstrate a business’ commitment to creating and defining a
            Culture of Health Awareness™.
          </p>
          <div class="text-center">
            <v-btn
              dark
              rounded
              large
              color="#228587"
              class="px-11 mt-5 mt-sm-7 font-weight-bold"
              router-link
              to="/about"
              >LEARN MORE</v-btn
            >
          </div>
        </v-container>
      </v-col>

      <v-col class="mb-5" cols="12">
        <v-card tile elevation="0" class="px-sm-10 px-md-16" color="#0e2525">
          <v-row align="start" justify="center">
            <v-col
              cols="12"
              md="6"
              class="flex pt-0 pt-sm-15 pb-sm-5 py-md-0 my-5 mx-sm-auto my-auto"
              justify="center"
              align="center"
            >
              <v-img
                class="
                  mb-5
                  my-sm-15 my-md-10
                  ml-lg-10
                  mt-sm-0
                  my-lg-10
                  mx-sm-auto
                  mb-sm-0
                "
                :max-width="groceryWidth"
                src="@/assets/grocery.png"
              />
            </v-col>
            <v-col cols="12" md="6" class="flex my-5 mt-md-9 mx-sm-auto">
              <p
                class="
                  clientsText
                  text-h4 text-lg-h2 text-md-h3 text-sm-h4
                  pl-8
                  mb-0 mb-sm-3
                "
              >
                Our Clients
              </p>
              <p
                class="
                  clientsSubText
                  text-lg-h6
                  px-8 px-sm-0
                  mt-1 mt-sm-0
                  mb-11 mb-md-0
                "
              >
                ZaaWink’s target market is business owners, human resources
                directors, management executives, managers, presidents, or CEOs
                with any number of employees who want to ensure a commitment to
                a healthy work environment.
                <br />
                <br />
                We focus on Business to Customer (B2C) businesses with
                forward-facing, high contact customer service. These businesses
                are the most vulnerable to increased exposure to illnesses.
              </p>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col class="mb-5 mt-8" cols="12">
        <v-card tile elevation="0" class="text-center">
          <p class="smartwatchText text-h4 text-lg-h2 text-md-h3 text-sm-h4">
            Our Smartwatches
          </p>
        </v-card>
      </v-col>
      <v-row align="center" justify="center" class="mb-14">
        <v-card tile elevation="0" class="order-2 order-sm-1">
          <v-img
            :max-width="zazaTwoWidth"
            :max-height="zazaTwoHeight"
            class="mx-16 mx-sm-0"
            src="@/assets/ZazaTwoAlternate.png"
            contain
          />
          <p class="text-center watchCaption pt-5 mb-1">ZaaTwo</p>
          <p class="text-center watchPrice">$99</p>
        </v-card>
        <v-card tile elevation="0" class="order-1 order-sm-2">
          <v-img
            :max-width="zazaOneWidth"
            class="mx-16 mx-sm-0"
            src="@/assets/ZazaOne.png"
          />
          <p class="text-center watchCaption pt-5 mb-1">ZaaOne</p>
          <p class="text-center watchPrice">$149</p>
        </v-card>
        <v-card tile elevation="0" class="order-3 order-sm-3">
          <v-img
            :max-width="zazaThreeWidth"
            class="mx-16 mx-sm-0"
            src="@/assets/ZazaThree.png"
          />
          <p class="text-center watchCaption pt-5 mb-1">ZaaThree</p>
          <p class="text-center watchPrice">$49</p>
        </v-card>
      </v-row>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Home",

  // Used for title naming
  mounted() {  
    document.title = "ZaaWink";  
  },

  data: () => ({}),
  computed: {
    imgWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 350;
        case "sm":
          return 350;
        case "md":
          return 542;
        case "lg":
          return 542;
        case "xl":
          return 542;
        default:
          return 542;
      }
    },
    groceryWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 700;
        case "sm":
          return 730;
        case "md":
          return 542;
        case "lg":
          return 542;
        case "xl":
          return 542;
        default:
          return 542;
      }
    },
    zazaOneWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 260;
        case "sm":
          return 290;
        case "md":
          return 420;
        case "lg":
          return 420;
        case "xl":
          return 420;
        default:
          return 420;
      }
    },
    zazaTwoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 150;
        case "sm":
          return 150;
        case "md":
          return 196;
        case "lg":
          return 196;
        case "xl":
          return 196;
        default:
          return 196;
      }
    },
    zazaTwoHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 200;
        case "sm":
          return 200;
        case "md":
          return 262;
        case "lg":
          return 262;
        case "xl":
          return 262;
        default:
          return 262;
      }
    },
    zazaThreeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 150;
        case "sm":
          return 150;
        case "md":
          return 196;
        case "lg":
          return 196;
        case "xl":
          return 196;
        default:
          return 196;
      }
    },
  },
};
</script>
<style scoped>
.clientsText {
  color: white;
  font-size: 56px;
  font-weight: 700;
}
.clientsSubText {
  color: white;
  font-size: 20px;
  line-height: 25px;
}
.watchCaption {
  color: #333333;
  font-size: 13px;
  font-weight: 700;
}
.watchPrice {
  color: #333333;
  font-size: 11px;
  opacity: 0.8;
}
.hidden-custom {
  display: none !important;
}

/* for phone view or vueitfy xs breakpoint */
@media (max-width: 600px) {
  .background {
    background: url("../assets/rectangle-long.png") center center no-repeat !important;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 768px;
    background-size: cover !important;
  }
  .mainText {
    color: #88d641;
    line-height: 38px;
  }
  .mainSubText {
    color: white;
    line-height: 20px;
    /* width: 472px; */
    font-size: 20px;
    font-weight: 400;
  }
  .hidden-small {
    display: none !important;
  }
  .infoText {
    text-align: center;
    color: #333333;
    /* font-size: 35px; */
    /* font-weight: 700; */
  }
  .infoSubText {
    line-height: 22px;
    text-align: center;
    color: black;
    font-size: 19px;
  }
  .clientsSubText {
    color: white;
    font-size: 20px;
    line-height: 25px;
  }
  .smartwatchText {
    /* font-size: 20px; */
    font-weight: 700;
    color: #333333;
  }
}
/* vuetify sm breakpoint */
@media (max-width: 960px) and (min-width: 600px) {
  .background {
    background: url("../assets/rectangle.png") center center no-repeat !important;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 700px;
    background-size: cover !important;
  }
  .mainText {
    color: #88d641;
    line-height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 75%; */
    /* font-size: 53px; */
    /* font-weight: 700px; */
  }
  .mainSubText {
    color: white;
    line-height: 22px;
    /* width: 75%; */
    font-size: 15px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(-2%);
  }
  .btn-center {
    display: absolute;
    left: 50%;
    transform: translateX(-70%);
  }
  .hidden-small {
    display: inline !important;
  }
  .infoText {
    text-align: center;
    color: #333333;
    /* font-size: 56px; */
    /* font-weight: 700; */
  }
  .infoSubText {
    line-height: 22px;
    text-align: center;
    color: black;
    font-size: 20px;
  }
  .clientsSubText {
    color: white;
    font-size: 20px;
    line-height: 22px;
  }
  .smartwatchText {
    font-size: 56px;
    font-weight: 700;
    color: #333333;
  }
  .hidden-custom {
    display: inline !important;
  }
}
/* vuetify md breakpoint */
@media (max-width: 1264px) and (min-width: 960px) {
  .background {
    background: url("../assets/rectangle.png") center center no-repeat !important;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 700px;
    background-size: cover !important;
  }
  .mainText {
    color: #88d641;
    line-height: 55px;
    /* font-size: 53px; */
    /* font-weight: 700px; */
  }
  .mainSubText {
    color: white;
    line-height: 22px;
    /* width: 472px; */
    font-size: 15px;
    font-weight: 400;
  }
  .hidden-small {
    display: inline !important;
  }
  .infoText {
    text-align: center;
    color: #333333;
    /* font-size: 56px; */
    /* font-weight: 700; */
  }
  .infoSubText {
    line-height: 23px;
    text-align: center;
    color: black;
    font-size: 20px;
  }
  .clientsSubText {
    color: white;
    font-size: 20px;
    line-height: 23px;
  }
  .smartwatchText {
    font-size: 56px;
    font-weight: 700;
    color: #333333;
  }
}
@media (min-width: 1264px) {
  .background {
    background: url("../assets/rectangle.png") center center no-repeat !important;
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 700px;
    background-size: cover !important;
  }
  .mainText {
    color: #88d641;
    line-height: 70px;
    /* font-size: 53px; */
    /* font-weight: 700px; */
  }
  .mainSubText {
    color: white;
    line-height: 32px;
    /* width: 472px; */
    font-size: 20px;
    font-weight: 400;
  }
  .infoText {
    text-align: center;
    color: #333333;
    /* font-size: 56px; */
    /* font-weight: 700; */
  }
  .infoSubText {
    line-height: 32px;
    text-align: center;
    color: black;
    font-size: 20px;
  }
  .clientsSubText {
    color: white;
    font-size: 20px;
    line-height: 32px;
  }
  .smartwatchText {
    font-size: 56px;
    font-weight: 700;
    color: #333333;
  }
}
</style>