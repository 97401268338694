<template>
  <div>
    <Modal v-model="showModal" @close="showModal = false" />
    <v-card tile elevation="0" class="my-2" color="#179b9e" height="10px">
    </v-card>
    <v-card tile elevation="0" class="pt-0 pb-3" color="#102126">
      <v-row
        class="mx-auto pt-5 mb-5"
        :justify="footerJustify"
        align="center"
        style="width: 85%"
      >
        <v-col cols="12" sm="12" md="4" class="px-0">
          <v-img
            class="shrink mx-auto ml-md-0"
            contain
            max-width="320px"
            src="@/assets/Zaawink-long-logo.png"
          />
        </v-col>
        <v-spacer
          v-show="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm"
        ></v-spacer>
        <v-col
          cols="12"
          sm="3"
          md="2"
          lg="2"
          xl="2"
          class="white--text px-16 px-sm-0 px-md-3 footerCol contactCol ml-sm-3 ml-md-0"
          align-self="start"
        >
          <h4
            class="mb-4 text-xs-subtitle-2 text-md-subtitle-1 footer-title"
            style="color: #148588"
          >
            GET IN TOUCH
          </h4>
          <h4
            class="text-sm-body-2 text-lg-body-1"
            style="display: inline-block"
          >
            Info@zaawink.com
          </h4>
        </v-col>
<!-- keep the empty lg & xl tag -- for some reason it helps with formatting? -->
        <v-col
          cols="12"
          sm="4"
          md="4"
          lg=""
          xl="2"
          class="text-end footerCol px-16 px-sm-0 mx-sm-3 mx-md-0"
          align-self="start"
        >
          <h4
            class="mb-5 text-left text-xs-subtitle-2 text-md-subtitle-1 footer-title"
            style="color: #148588"
          >
            JOIN OUR MAILING LIST
          </h4>
          <form
            action="https://zaawink.us5.list-manage.com/subscribe/post?u=e5b34bb23d23e3ed037ed5190&amp;id=967ba3a666"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
            novalidate
          >
            <!-- this div contains all the css for the background /layout -->
            <div id="mc_embed_signup_scroll" class="white--text">
              <div
                class="mc-field-group email-div"
              >
                <input
                  type="email"
                  value=""
                  name="EMAIL"
                  class="required email white email-input"
                  placeholder="Email Address"
                  id="mce-EMAIL"
                />
                <input
                  type="submit"
                  value="GO"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  class="button subscribe-btn"
                />
              </div>
              <div id="mce-responses" class="clear">
                <div
                  class="response"
                  id="mce-error-response"
                  style="display: none"
                ></div>
                <div
                  class="response"
                  id="mce-success-response"
                  style="display: none"
                ></div>
              </div>
              <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
              <div style="position: absolute; left: -5000px" aria-hidden="true">
                <input
                  type="text"
                  name="b_e5b34bb23d23e3ed037ed5190_967ba3a666"
                  tabindex="-1"
                  value=""
                />
              </div>
            </div>
          </form>
        </v-col>
      </v-row>

      <v-divider
        class="mx-auto"
        width="85%"
        style="height: 1px; opacity: 0.19"
        color="#fafafa"
      ></v-divider>

      <v-row
        class="text-center text-sm-left mx-auto my-1"
        justify="space-between"
        align="center"
        style="width: 85%"
      >
        <v-col cols="12" sm="4" md="4" class="copyright pb-0 pa-sm-3">
          <p class="white--text text-center text-sm-left mb-0 mb-sm-4 ">
            <a
              class="white--text text-decoration-underline"
              @click="toPrivacyPolicy"
            >
              Privacy Policy
            </a>
            |
            <a class="white--text text-decoration-underline" @click="toTerms">
              Terms and Conditions
            </a>
          </p>
          <!-- <p class="mb-1 mb-sm-4" v-if="this.$vuetify.breakpoint.sm">
            Design with love © All rights reserved
          </p> -->
        </v-col>
        <v-col
          cols="12"
          sm="4"
          class="text-center pa-sm-3 pt-1"
          align-self="center"
        >
          <p class="copyright mb-1 mb-sm-4">
            Design with love © All rights reserved
          </p>
        </v-col>
        <v-col cols="12" sm="4" :align="btnAlign" class="pt-0 white--text">
          <v-btn
            outlined
            fab
            x-small
            dark
            elevation="0"
            class="mr-1 my-auto"
            href="https://www.facebook.com/ZaaWink"
            target="_blank"
          >
            <v-img src="@/assets/facebook.png" style="max-width: 10px" />
          </v-btn>
          <v-btn
            outlined
            fab
            x-small
            dark
            elevation="0"
            class="mx-1"
            href="https://twitter.com/ZaaWink"
            target="_blank"
          >
            <v-icon> mdi-twitter </v-icon>
          </v-btn>
          <v-btn
            outlined
            fab
            x-small
            dark
            elevation="0"
            class="ml-1"
            href="https://instagram.com/ZaaWink"
            target="_blank"
          >
            <v-icon> mdi-instagram </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },

  data: () => ({
    showModal: false,
  }),

  computed: {
    btnAlign() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "center";
        case "sm":
          return "end";
        case "md":
          return "end";
        case "lg":
          return "end";
        case "xl":
          return "end";
        default:
          return "end";
      }
    },
    footerJustify() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "space-between";
        case "sm":
          return "center";
        case "md":
          return "space-between";
        case "lg":
          return "space-between";
        case "xl":
          return "space-between";
        default:
          return "space-between";
      }
    },
  },

  methods: {
    toPrivacyPolicy() {
      let route = this.$router.resolve({ path: "/PrivacyPolicy" });
      window.open(route.href);
    },
    toTerms() {
      let route = this.$router.resolve({ path: "/TermsConditions" });
      window.open(route.href);
    },
  },
};
</script>

<style scoped>
.zaawink {
  color: #fafafa;
  font-size: 55px;
  font-weight: 300;
}
.copyright {
  color: #fafafa;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
}
.subscribe-btn {
  display: inline-block !important;
  margin: 0 0 0 0 !important;
  height: 39px;
  background-color: #179b9e;
  padding: 0px 20px;
  font-size: 0.875rem;
  border-radius: 6px;
  border: 2px solid #179b9e !important;
  font-weight: 700 !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.subscribe-btn:hover {
  background-color: #158a8d;
  border: 2px solid #158a8d !important;
  outline: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.3);
}
.subscribe-btn:active {
  vertical-align: top;
  padding: 8px 20px 6px;
}
.email-div {
  display: flex; 
  flex-direction: row; 
  max-width: 400px;
}
.email-input {
  display: inline-block;
  line-height: 35px;
  background-color: #102126 !important;
  box-shadow: inset 0px 1px 3px 0px rgb(0 0 0 / 8%);
  border: 2px solid #27505c !important;
  border-radius: 6px;
  padding: 0 20px;
  color: #fafafa;
  flex-grow: 2;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.email-input:focus-visible {
  border: 2px solid #179b9e !important;
  outline: none;
}
/* for weird resizing issues */
@media only screen and (max-width: 450px) {
  .footerCol {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
@media only screen and (min-width: 450px) and (max-width: 515px) {
  .footerCol {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
/* Vuetify SM breakpoint */
@media only screen and (min-width: 600px) and (max-width: 960px) {
  .copyright {
    font-size: 12px;
  }
  .footer-title {
    font-size: 15px;
  }
  .email-input {
    width: 165px;
    flex-grow: 0;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1040px) {
  .contactCol {
    margin-right: 10px;
  }
}
/*  Vuetify MD breakpoint */
@media only screen and (min-width: 960px) and (max-width: 1264px) {
  
}
/*  Vuetify LG breakpoint */
@media only screen and (min-width: 1264px) {
  .email-div {
    float: right;
    width: 100%;
  }
}
@media only screen and (min-width: 2830px) {
  .footerCol {
    max-width: 400px;
  }
}
</style>
